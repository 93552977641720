var sm = 640;
var md = 768;
var lg = 992;

$(document).ready(function () {

	if($('.cbn-details, .insp-details').length > 0){
		var vertCenterInterval = setInterval(function(){
			$('.cbn-details, .insp-details').each(function(){
				if($(this).data('centered') != 1){
					cbnDetailsVertCenter($(this));
				}
			});
		},2000);
	}
	
	$(window).on('resize',function(){
		$('.cbn-details, .insp-details').each(function(){
			cbnDetailsVertCenter($(this));
		});
	});
	
	if($('.cbn-scroll').length > 0){
		setTimeout(function(){
			if($('.cssanimations').length > 0){
				$('.cbn-scroll').addClass('oov');
			}else {
				$('.cbn-scroll').animate({bottom:'-150px'},'slow');
			}
		},5000);
		
		$(window).one('scroll',function(){
			if($('.cssanimations').length > 0){
				$('.cbn-scroll').addClass('oov');
			}else {
				$('.cbn-scroll').animate({bottom:'-150px'},'slow');
			}
		});	
	}
	
	$(document).on('click', '.cbn-special-offers', function(e){
		e.preventDefault();
		
		if( $.magnificPopup ){
			var magnificPopup = $.magnificPopup.instance.close();
		}
		else{
			//Close our lightbox
			hideLightbox();
		}
		
		var headerLink = $('#navbar').find('.navbar-special-offers:first');
		var headerLinkTarget = headerLink.prop('href');
		// add additional tracking var for spec offers button in come back new section
		headerLink.prop('href',headerLinkTarget+'?code=CMBKSPCLOF');

		hasSignedUpForSpclOffers = getCookie("SIGNED_UP_SPCL_OFFERS");
		if(!(hasLoggedIn()) && !hasSignedUpForSpclOffers) {
			headerLink.trigger('click');
			headerLink.prop('href',headerLinkTarget);
		}else{
			headerLink.trigger('click');
			headerLink.prop('href',headerLinkTarget);
		}

	});
	
	var tabMenuCheck = setInterval(function(){
		if($('.drop-tab-ul li').length > 0){
			clearInterval(tabMenuCheck);
			setActiveTab();
		}		
	},500);
	
	$('#destinations-ports').addClass('cbn-ports top-spacing-0');
	
});

function cbnDetailsVertCenter(target){
	if(target.hasClass('right-img')){
		var comp = target.next().height();
	}else{
		var comp = target.prev().height();
	}
	var t = 0;
	var b = 0;
	var windowWidth = $(window).width();
	if(windowWidth < md){
		if(target.parent().hasClass('exclusive-deals') == true){
			return;
		}
		t = 30;
		b = 30;
	} else {
		t = (comp - target.height())/2;
		b = 'auto';
		if(t < 0){
			t = 0;
			b = 10;
		}
	}
	target.css({
		'margin-top':t,
		'margin-bottom':b
	});
	target.data('centered',1);
}
